<template>
  <div>
    <h3 ref="ia">
      What is a <i>For</i> Loop?
    </h3>
    <p>
      The <i>for</i> loop is a common construct in computer programming. A <i>for</i> loop is simply used to iterate
      repeat an action a given number of times. For example, it is a common scenario in computer programming
      to execute a block of code over and over for multiple times &mdash; each time with a different value of the control parameter.
    </p>
    <h3 ref="st">
      Structure of a <i>For</i> Loop
    </h3>
    <p>
      A for loop has four essential components: initialization, conditional, incrementation and an action statement.
      <ul style="list-style-type:square;">
        <li>
          <h5>Initialization </h5>
          This statement is used to declare and/or initialize the loop control variable, e.g., i=0.
        </li>
        <li>
          <h5>Conditional </h5>
          This is a Boolean statement used to evaluate the condition for execution of the body of the loop, e.g., i<=5.
        </li>
        <li>
          <h5>incrementation </h5>
          This statement is used to increment the counter variable, e.g., i++
        </li>
        <li>
          <h5>Action Statement </h5>
          This represents the block of code that is iteratively executed as long as the condition checked remains true.
        </li>
      </ul>

      Shown below is the generic structure of a for loop.
      <br><br>
      <pre>
for (initialization; conditional; incrementation)
{
action_statement;
}
end
</pre>
    </p>
    <h3>Example of <i>For</i> Loop</h3>
    <p>
      The syntax used to write the <i>for</i> loop varies from language to language. Examples of
      <i>for</i> loops from Python, C and others are given below. The examples print out
      the numbers 0-9 (inclusive of 0 and 9).
    </p><h5 ref="pyt">
      Syntax of a <i>for</i> loop in Python3 Programming Language
    </h5>
    <pre>
for i in range(0, 10):
    print(i)
    </pre>
    </p>
    <h5 ref="c">
      Syntax of a <i>for</i> loop in <i>C</i> Programming Language:
    </h5>
    <p>
      <pre>
for (int i = 0; i < 10; ++i) {
    printf("%d\n", i);
}
    </pre>
    </p>
    <h3 ref="pg">
      MagicGraph | <i>For</i> Loop Explained
    </h3>
    <p>
      This MagicGraph offers a visually interactive demonstration of how a for loop works.
      This simple computer program uses the for loop to incrementally move the rabbit from one point to another in <span style="font-family:'Courier New'">N = 10</span> number of steps.
      <ul style="list-style-type:square;">
        <li>
          <h5>Initialization </h5>
          The loop control variable <span style="font-family:'Courier New'">i</span>  is initialized to a value of <span style="font-family:'Courier New'">1</span> (the yellow light is on).
        </li>
        <li>
          <h5>Conditional </h5>
          Boolean statement to evaluate if <span style="font-family:'Courier New'">i</span> is less than or equal to <span style="font-family:'Courier New'">N</span>. If it is, the Boolean statement returns <span style="font-family:'Courier New'">True</span> (green light). Otherwise, it returns <span style="font-family:'Courier New'">False</span> (the red light is on).
        </li>
        <li>
          <h5>Incrementation </h5>
          Then, <span style="font-family:'Courier New'">i</span> is updated in increments of <span style="font-family:'Courier New'">1</span> as long as its value is smaller than or equal to <span style="font-family:'Courier New'">N</span> (the default value is 10 but it can be modified).
        </li>
        <li>
          <h5>Action Statement </h5>
          For each increment (while the green light is on), the <span style="font-family:'Courier New'">x</span> and <span style="font-family:'Courier New'">y</span> positions of the rabbit are updated by specified increments (defaults are 1 but they can be modified).
        </li>
      </ul>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ForLoopExplained',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'For Loop Example';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'For Loop Definition', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'MagicGraph: Tying a Flemish Knot' ,img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Flemish Knot',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Projectile Motion'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
#jxgbox1 input {border-style:solid;border-radius:4px;background-color:#FEBD49}
</style>
