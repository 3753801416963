const Boxes = {
    box1: function () {
      var brd3 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 30, 30,-10],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}});
      //brd3.suspendUpdate();
	  brd3.options.layer['image']=16;
	  var resize = function () {
            brd3.resizeContainer(brd3.containerObj.clientWidth, brd3.containerObj.clientHeight, true);
            brd3.fullUpdate();
            };
      window.onresize = resize;
	  brd3.create('text',[10, 28, '<b>For Loop</b> '],{cssStyle:'fontFamily:Oswald;', anchorX:'middle', anchorY: 'middle', fontSize:function(){return Math.round(32*brd3.canvasWidth/500.)}, fixed:true});
//congruent triangle//
    var x =0.;
	  var y =0.;
	  var alpha =0.;
	  var imax =  brd3.create('input', [5, 24, 10, 'For&nbsp;[&nbsp;i&nbsp;=&nbsp;1;&nbsp;&nbsp;i++;&nbsp;&nbsp;i&nbsp;&lt; &nbsp;'], {cssStyle: 'fontFamily:Lato;width:5%;', fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)},fixed:true, maxlength:2});
	  //
	  var xpp = brd3.create('input', [5, 22, 1., '&nbsp;&nbsp;&nbsp;x&nbsp;=&nbsp;x&nbsp;+&nbsp;&nbsp;'], {cssStyle: 'fontFamily:Lato;width:5%;', fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)},fixed:true, maxlength:1});
	  //
	  //
	  var ypp = brd3.create('input', [5, 20, 1., '&nbsp;&nbsp;&nbsp;y&nbsp;=&nbsp;y&nbsp;+&nbsp;&nbsp;'], {cssStyle: 'fontFamily:Lato;width:5%;', border:{strokeWidth:2}, fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)},fixed:true, maxlength:1});
	 //
	 var pt2 = brd3.create('point', [0., 0.0],{withLabel:false, fixed:false, visible:false});
	 var pt3 = brd3.create('point', [0., 0.0],{withLabel:false, fixed:false, visible:false});
	 var pt33 = brd3.create('point', [5, 0.0],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false});
	 var pt40 = brd3.create('point', [function(){return xpp.Value()*5}, 0.0],{withLabel:false, fixed:false, visible:false});

	 var pt4 = brd3.create('point', [5, 0.0],{withLabel:false, fixed:false, visible:false});
	 var pt44 = brd3.create('point',[5, function(){return ypp.Value()*5/xpp.Value()}],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false});
//
	 var arr2 = brd3.create ('arrow', [pt2, pt3], {visible: false, name:'h', strokeColor:'#80879A', strokeWidth: 2});
	 var arr3 = brd3.create ('arrow', [pt33,pt4], {visible: false, name:'h', strokeColor:'#80879A', strokeWidth: 2});

	  //
	  brd3.create('image',['/assets/rectt.svg', [4.5, 9.25], [14, 24]], {fixed:true, highlight:false, fillOpacity:1, shadow:true});
      var t = brd3.create('turtle',[0, 0], {visible:false, strokeWidth:0});
	  var pts = brd3.create('point', [function(){return t.X()}, function(){return t.Y()}],{name:'', size:function(){return 2*brd3.canvasHeight/800},color:'black', trace:true});
	  brd3.create('image',['/assets/rabbit.svg', [function(){return t.X()-1.25}, function(){return t.Y()+0.25}], [2.5, 2.5]], {fixed:true});
      t.hideTurtle();
      //t.setPos(0,3);
      //t.setPenColor('purple');
      var i =1;
	  var runXcontrol= 0;
      //var beta =0;
      //Starting Point
      brd3.create('point', [0, 0],{name:'Start', size:function(){return 4*brd3.canvasHeight/800}, fillColor:'white', strokeColor:'black', fixed:true, label:{anchorX:'middle', display:'internal', anchorY: 'top', fontSize:function(){return 18*brd3.canvasHeight/800}, offset:[0, 0], cssStyle:'fontFamily:Oswald;'}});
	  //Finish Point
	  brd3.create('point', [() => x, ()=>y],{visible:()=>i==imax.Value(), name:'Finish', size:function(){return 4*brd3.canvasHeight/800}, fillColor:'white', strokeColor:'black', fixed:true, label:{anchorX:'middle', display:'internal', anchorY:'top', fontSize:function(){return 18*brd3.canvasHeight/800}, offset:[0, 0], cssStyle:'fontFamily:Oswald;'}});
	  //
      //var tf = brd3.create('transform', [function(){return a.Y()-3+1}, function(){return a.Y()-3+1}], {type: 'scale'});
      var runX = function() {
	  if(runXcontrol!=1){return;}
		if (i <imax.Value()) {// stop after two rounds
          i += 1;
		  x = x +1*xpp.Value();
		  y = y +1*ypp.Value();
		  //t.forward(1);
		  t.moveTo([x, y]);
          //t.setPenColor('red');
        }
		else{return;}
		//if(i==10){
		//t.right(90);
		//alpha=-90;
		//t.setPenColor('blue');
		//}
        //if (i>=10 &&i<= 20) {// stop after two rounds
        //  i += 1;
		//  t.forward(1);
        //  t.setPenColor('blue');
		if(i<=imax.Value() & runXcontrol==1){
        setTimeout(runX, 600);
		}
       }
      //var hat =brd3.create('point', [-6.75, 4.], {visible:false});
      var sailor = brd3.create('image',['/assets/play-button.svg', [-6, 18.], [6, 6]], {fixed:true});
	   brd3.create('text', [-5, 24.5, "Tap to Start!"], {visible: true, fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	   brd3.create('text', [10, 16, "Orange fields are modifiable."], {anchorX:'middle', anchorY:'middle', visible: true, fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	  var nmax =brd3.create('image',['/assets/yellowLight.svg', [25, 22], [2, 2]], {fixed:true, highlight:false, fillOpacity:function(){if(i==1){return 1} else{return 0.1}}});
      var nx =brd3.create('image',['/assets/redLight.svg', [25, 20], [2, 2]], {fixed:true, highlight:false, fillOpacity:function(){if(i>1 && i<imax.Value()){return 1} else{return 0.1}}});
	  var ny = brd3.create('image',['/assets/greenLight.svg', [25, 18], [2, 2]], {fixed:true, highlight:false, fillOpacity:function(){if(i==imax.Value()){return 1} else{return 0.1}}});
	  //var spe = brd3.create('image',['/assets/speaker.svg', [-6.8, 1.7], [0.75, 0.75]], {fixed:true, visible:function(){if(alpha > 0 && alpha < 17 || alpha > 20 && alpha < 40 || alpha >=45 && alpha < 60 || alpha >=64 && alpha <= 80 || alpha >80 && alpha<90 || alpha >92){return true } else {return false}}});
      //var rope = brd3.create('image',['assets/fiber.svg', [-4.25, 3.], [0.5, 0.5]], {attractors:[hat],attractorDistance:1,  snatchDistance:2.});
      //var sail = function(){if(rope.X()-hat.X()<=0.0001 && rope.Y()-hat.Y()<=0.0001){return run();}};
	  var res = function(){
		  pts.moveTo([0, 0]);
		  alpha=0;
		  t.cs();
		  i=1;
		  runXcontrol=0;
		  x=0;
		  y=0;
		  t.moveTo([0,0]);
		  pts.clearTrace();
		  t.left(0);
		  pt3.moveTo([0,0]);
		  pt4.moveTo([5,0]);
		  slopeX.setAttribute({visible: false});
		  slopeY.setAttribute({visible: false});
	  };
	  sailor.on('down', function(){res(); runXcontrol=1; runX(); slope1(100); slope2(500);});
	  //brd3.create('image',['/assets/start.svg', [-5, 14.], [4, 4]], {fixed:true, visible:()=>i==1});
	  //brd3.create('image',['/assets/finish.svg', [-5, 14.], [4, 4]], {fixed:true, visible:()=>i==imax.Value()});
	  //
	   brd3.create('text', [23, 23, function(){return 'i = ' + i;}],{visible:()=> i==1, display:'internal', color:'blue', anchorX:'middle', anchorY: 'middle', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)}, parse:true,fixed:true});
	  //
	  brd3.create('text', [23, 21, function(){return 'i = ' + i;}],{visible:()=> i>1&&i<imax.Value(),display:'internal', color:'blue', anchorX:'middle', anchorY: 'middle', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)}, parse:true,fixed:true});
	  //
	  brd3.create('text', [23, 19, function(){return 'i = ' + i;}],{visible:()=> i==imax.Value(), display:'internal', color:'blue', anchorX:'middle', anchorY: 'middle', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)}, parse:true,fixed:true});

	  brd3.create('text', [5, 18, "End"], {visible: true, fixed: true, fontSize:function(){return 22*brd3.canvasHeight/800}, cssStyle:'fontFamily:Lato;'});
	   brd3.create('text', [16.7, 24, "]"], {visible: true, fixed: true, fontSize:function(){return 22*brd3.canvasHeight/800}, cssStyle:'fontFamily:Lato;'});
//
	  var slopeX = brd3.create('text',[function(){return pt33.X()/2}, function(){return pt2.Y()-1}, function(){return xpp.Value()}],{visible: false, cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd3.canvasWidth/500.)},fixed:true});
//
      var slopeY = brd3.create('text',[function(){return pt33.X() + 0.2}, function(){return pt4.Y()/2 }, function(){return ypp.Value()}],{visible: false, cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd3.canvasWidth/500.)},fixed:true});
function movePoint3(){
            pt3.moveTo([pt33.X(), pt33.Y()], 500);
            arr2.setAttribute({visible: true});
            slopeX.setAttribute({visible: true});
            }
//
		function slope1(t){
		setTimeout(function(){ movePoint3(); }, t);
		}
//-------------------
	   function movePoint4(){
			pt4.moveTo([pt33.X(), pt33.Y()+5*ypp.Value()/xpp.Value()], 500);
            arr3.setAttribute({visible: true});
            slopeY.setAttribute({visible: true});}
//
   	function slope2(t){
    setTimeout(function(){movePoint4(); }, t);
	}
//
      brd3.unsuspendUpdate();
	  let magicGraph = document.querySelector("#jxgbox1");
      let inputFields = magicGraph.querySelectorAll("input");
      var onEachPress = function(event) { res(); }
      inputFields.forEach(function(field) {
      field.addEventListener('input', onEachPress);
    });
	},
}
export default Boxes;
